import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";

import HomePage from './components/HomePage';



function App() {
  return (
    <Router>
      <Helmet>
        <script src="../assets/js/jquery.min.js"></script>
        <script src="../assets/js/bootstrap.bundle.min.js"></script>
        <script src="../assets/js/swipper-bundle.min.js"></script>
        <script src="../assets/js/jquery.meanmenu.min.js"></script>
        <script src="../assets/js/wow.min.js"></script>
        <script src="../assets/js/odometer.min.js"></script>
        <script src="../assets/js/jquery.modal.min.js"></script>
        <script src="../assets/js/appear.min.js"></script>
        <script src="../assets/js/main.js"></script>
      </Helmet>
      <div>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </main>
      </div>
    </Router>


  );
}

export default App;