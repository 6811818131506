import React, { useEffect } from 'react';
import myPhoto from '../assets/img/personal-info/Amir-Ali-Rahman.webp';
import founded from '../assets/img/founded/founded.png';
import { Link } from 'react-router-dom';

import '../assets/css/fontawesome.min.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/animate.min.css';
import '../assets/css/all.min.css';
import '../assets/css/odometer.min.css';
import '../assets/css/meanmenu.css';
import '../assets/css/swipper.css';
import '../assets/css/main.css';


function HomePage() {
    useEffect(() => {
        document.title = "Amir Ali Rahman | Best Web Developer in New Delhi";
    }, []);

    return (

        <div className="page-wrapper home-3" style={{ backgroundColor: "#eee" }}>


            <div className="amir-header-area mb-30 z-index-5">
                <div className="container">
                    <div class="row justify-content-center">
                        <div class="col-9 col-md-4 col-lg-4">
                            <div class="amir-header-logo text-center">
                            <h3 style={{ textTransform: 'uppercase', backgroundColor: '#ff6080', borderRadius: '10px', color: '#fff' }}>
                                Amir Ali Rahman
                            </h3>
                            </div>
                        </div>
                        </div>
                    {/* Mobile Menu */}
                    <div className="mobile-menu-wrap">
                        <div className="mobile-menu mobile_menu_3">
                        </div>
                    </div>

                </div>
            </div>

            <div className="container z-index-3">
                <div className="row">

                    {/* Personal Info */}
                    <div className="col-xxl-4 col-xl-4 col-lg-4">
                        <div className="amir-parsonal-info-area">
                            <div className="amir-parsonal-info-wrap">

                                {/* Image */}
                                <div className="amir-parsonal-info-img">
                                    <img src={myPhoto} alt="avatar" />
                                </div>

                                {/* Name */}
                                <h4 className="amir-parsonal-info-name">
                                    <a href="#">Amir Ali Rahman</a>
                                </h4>
                                <span className="amir-parsonal-info-bio mb-15">Web Developer</span>

                                {/* Social Links */}
                                <ul className="amir-parsonal-info-social-link mb-30">
                                    <li>
                                        <a href="https://www.facebook.com/amiralirahman07" target="_blank" className="facebook">
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://x.com/amir_ali_rahman" target="_blank" className="twitter">
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://instagram.com/amir_ali_rahman" target="_blank" className="instagram">
                                            <i className="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/in/amir-ali-rahman" target="_blank" className="linkedin">
                                            <i className="fa-brands fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>

                                {/* Contact */}
                                <div className="amir-parsonal-info-contact mb-30">
                                    <div className="amir-parsonal-info-contact-item phone">
                                        <div className="icon">
                                            <i className="fa-solid fa-mobile-screen-button"></i>
                                        </div>
                                        <div className="text">
                                            <span>Phone</span>
                                            <p>+91 9411202201</p>
                                        </div>
                                    </div>

                                    <div className="amir-parsonal-info-contact-item email">
                                        <div className="icon">
                                            <i className="fa-regular fa-envelope-open-text"></i>
                                        </div>
                                        <div className="text">
                                            <span>Email</span>
                                            <p style={{ textTransform: 'lowercase' }}>info@amiralirahman.com</p>
                                        </div>
                                    </div>

                                    <div className="amir-parsonal-info-contact-item location">
                                        <div className="icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        <div className="text">
                                            <span>Location</span>
                                            <p>New Delhi, India</p>
                                        </div>
                                    </div>

                                    <div className="amir-parsonal-info-contact-item calendar">
                                        <div className="icon">
                                            <i className="fa-light fa-calendar-days"></i>
                                        </div>
                                        <div className="text">
                                            <span>Birthday</span>
                                            <p>25th Jan</p>
                                        </div>
                                    </div>
                                </div>

                                {/* CV Button */}
                                <div className="amir-parsonal-info-btn">
                                    <a className="btn-1 circle" href="https://www.linkedin.com/in/amir-ali-rahman" target="_blank">
                                        <span className="icon">
                                            <i class="fa-solid fa-briefcase"></i>
                                        </span>
                                        hire me!
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* About Section */}
                    <div className="col-xxl-8 col-xl-8 col-lg-8">


                        <div className="amir-page-content-wrap">

                            {/* Page Title */}
                            <div className="section-wrapper pl-60 pr-60 pt-60">
                                <div className="amir-page-title-wrap mb-15">
                                    <h2 className="page-title">about me</h2>
                                    <p>👋 Hey, I'm Amir Ali Rahman, a professional web developer from the bustling streets of New Delhi! With three years of experience under my belt, I'm passionate about creating standout websites that make an impact.</p>

                                    <p>💻 Whether it's coding clean interfaces, optimizing user experiences, or diving into the latest web technologies, I'm all in. From brainstorming to launch day, I'm dedicated to bringing your digital dreams to life.</p>

                                    <p>🚀 Let's team up and build something extraordinary! Whether you're a startup, small business, or established brand, I'm here to turn your vision into reality. Get in touch, and let's make magic happen!</p>

                                </div>
                            </div>

                            {/* what-do */}
                            <div class="section-wrapper pl-60 pr-60">

                                <div class="amir-section-title-wrap mt-30 mb-20">
                                    <h3 class="section-title">What I do!</h3>
                                </div>

                                <div class="amir-what-do-wrap mb-30">
                                    <div class="row">
                                        {/* single item */}
                                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                                            <div class="amir-what-do-item bg-prink bg-blue">
                                                <div class="icon">
                                                    <i class="fa-regular fa-code"></i>
                                                </div>
                                                <div class="text">
                                                    <h4 class="title">Web Development</h4>
                                                    <p>I build dynamic and responsive websites that provide exceptional user experiences and drive results.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* single item */}
                                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                                            <div class="amir-what-do-item bg-prink">
                                                <div class="icon">
                                                    <i class="fa-light fa-swatchbook"></i>
                                                </div>
                                                <div class="text">
                                                    <h4 class="title">UI/UX Design</h4>
                                                    <p>I design engaging and intuitive interfaces that enhance user experience and make your brand stand out.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* single item */}
                                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                                            <div class="amir-what-do-item bg-catkrill">
                                                <div class="icon">
                                                    <i class="fa-solid fa-mobile-screen-button"></i>
                                                </div>
                                                <div class="text">
                                                    <h4 class="title">App Development</h4>
                                                    <p>I create robust and scalable applications that deliver seamless performance and meet your business needs.</p>
                                                </div>
                                            </div>
                                        </div>


                                        {/* single item */}
                                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                                            <div class="amir-what-do-item  bg-catkrill">
                                                <div class="icon">
                                                    <i class="fa-brands fa-searchengin"></i>
                                                </div>
                                                <div class="text">
                                                    <h4 class="title">SEO</h4>
                                                    <p>I optimize websites to improve their visibility on search engines and drive organic high quality traffic.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="section-wrapper pt-70 pb-60 pl-60 pr-60">
                                <div class="row">

                                    {/* skill */}
                                    <div class="col-xl-6 col-lg-7">
                                        <div class="amir-section-title-wrap mb-20">
                                            <h4 class="section-title">Working Skills</h4>
                                        </div>

                                        <div class="skill-bar-wrap">

                                            <div class="skill-bar-item mb-30">
                                                <div class="title-wrap">
                                                    <h5 class="title">Frontend Development</h5>
                                                    <span class="count">88%</span>
                                                </div>
                                                <div class="progress-bar-wrap">
                                                    <div class="progress-line progress-bg-1" style={{ width: '88%' }}>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="skill-bar-item mb-30">
                                                <div class="title-wrap">
                                                    <h5 class="title">Backend Development</h5>
                                                    <span class="count">88%</span>
                                                </div>
                                                <div class="progress-bar-wrap">
                                                    <div class="progress-line progress-bg-2" style={{ width: '88%' }}>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="skill-bar-item mb-30">
                                                <div class="title-wrap">
                                                    <h5 class="title">Mobile App</h5>
                                                    <span class="count">85%</span>
                                                </div>
                                                <div class="progress-bar-wrap">
                                                    <div class="progress-line progress-bg-3" style={{ width: '85%' }}>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    {/* gk */}
                                    <div class="col-xl-6 col-lg-5">
                                        <div class="amir-section-title-wrap mb-20">
                                            <h4 class="section-title">Knowledges</h4>
                                        </div>

                                        <div class="knowledeges-item-wrap">
                                            <span class="gk-item">ReactJS</span>
                                            <span class="gk-item">NodeJS</span>
                                            <span class="gk-item">MonogDB</span>
                                            <span class="gk-item">ExpressJS</span>
                                            <span class="gk-item">PHP</span>
                                            <span class="gk-item">Wordpress</span>
                                            <span class="gk-item">Python</span>
                                            <span class="gk-item">MySQL</span>
                                            <span class="gk-item">SEO</span>
                                        </div>

                                    </div>

                                </div>



                            </div>
                            {/* Services */}
                            <div class="section-wrapper pt-45 pb-60 pl-60 pr-60">

                                <div class="amir-section-title-wrap text-center mb-20">
                                    <h5 className="section-title" style={{ color: '#c9c9c9', textTransform: 'lowercase' }}>founded</h5>
                                </div>

                                <div class="amir-client-slider">

                                    <div class="swiper-slide">
                                        <img class="amir-client-slider-logo"
                                            src={founded} alt="" />
                                    </div>

                                </div>

                            </div>


                            <div class="footer-copyright text-center p-4 pt-25 pb-25">
                                <span>© 2024 All Rights Reserved | <a href="https://www.codinghub360.com"
                                    target="_blank" rel="noopener noreferrer">CodingHub360</a>.</span>
                            </div>

                        </div>
                    </div>


                </div>


            </div>

        </div>


    );
}

export default HomePage;
